


















/** {@link} https://feathericons.com/ */
import Vue from 'vue';

export default Vue.extend({
  name: 'SvgEur',
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 24,
    },
  },
  data() {
    return {
      d: 'M18 17.545c-.898.675-1.993.955-3.118.955-3.235 0-6.382-3-6.382-6.5s3.147-6.5 6.382-6.5c1.125 0 2.22.25 3.118.93',
    };
  },
});
